:root {
  --main-bg-dark: #073B4C;
  --main-bg-light: #118AB2;
  --main-green: #06D6A0;
  --main-bg-grey:#EDF2F4;
  --main-red: #EF476F;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom right, var(--main-bg-light), var(--main-bg-grey));
}

.addGridButton {
  opacity: .8;
}

.gridArea {
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
}

.headerItem {
  font-weight: bold;
  font-size: 3rem;
}

.boardItem {
  background-color: var(--main-bg-grey);
  border: 1px solid black;
  filter: drop-shadow(1px 1px 1px #39395f);
  padding: 5px;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.boardItem:hover {
  transform: scale(1.02);
}

.delete-icon {
  align-self: flex-end;
}

.delete-icon:hover {
  cursor: pointer;
}

.trash-container {
  display: flex;
  flex-direction: column;
}

.itemContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  text-align: center;
}

.isComplete {
  background-color: var(--main-green);
}

.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in {
  padding: 1rem;
	font-weight: bold;
}

.signOut {
  position: absolute;
  top: 1em;
  Right: 1em;
}

.goBack {
  position: absolute;
  top: 1em;
  left: 1em;
}

.boardTitle {
  font-weight: bold;
}

.boardSelectionContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.boardDetails {
  height: 100%;
  display: flex;
  align-items: center;
}

.selectionItem {
  background-color: var(--main-bg-grey);
  filter: drop-shadow(1px 1px 1px #39395f);
  padding: 5px;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.selectionItem:hover {
  transform: scale(1.02);
  cursor: pointer;
}

@media (orientation: portrait)
{
  .gridArea {
    width: 95%;
  }

  .gridItem {
    font-size: max(6px, 2vw);
  }
}

@media(orientation: landscape)
{
  .gridArea {
    width: calc(80vh);
  }

  .gridItem {
    font-size: max(6px, 2vh);
  }
}

.navBarTop
{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 5vh;
  z-index: 1000;

  display: flex;
}

.navButton {
  flex: 1;
  max-width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: .25rem .5rem .1rem .5rem;
  background-color: var(--main-bg-grey);
  border-radius: 10px;

  font-weight: bold;
}

.navButton:hover {
  cursor: pointer;
}

.navButton.disabled {
  opacity: .5;
}
.navButton.disabled:hover {
  cursor: not-allowed;
}